
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ItemDropContainer {
    z-index: 5;

    .ItemDrop {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        transform: scale(0.1) translate(0, 0);
        opacity: 0;
    }

    .ItemDropFilter {
        z-index: 3;
        opacity: 0.2 !important;
        animation: filter-animate-of-dropped-item 0.65s infinite linear;
    }
}

@keyframes filter-animate-of-dropped-item {
    0% {
        -webkit-filter: hue-rotate(90deg) saturate(450%) invert(100%);
    }
    50% {
        -webkit-filter: hue-rotate(none) saturate(none) invert(0);
    }
}

            