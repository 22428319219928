
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .DialogTradeContainer {
    @include modal-base-padding;
}

.DialogTrade {
    display: flex;
    width: 100%;
    height: 100%;
}

.DialogTradeLeftSide {
    width: 40%;
    height: 100%;

    .DialogTradeLeftSideList {
        list-style: none;
        margin: 0;
        padding: 0;

        .DialogTradeLeftSideListItem {
            height: 30px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #a68248;
            box-shadow: 0 0 1px #715529;
            margin-bottom: 4px;


        }

        .DialogTradeLeftSideListItemIcon {
            width: 30px;
            background: #b99458;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
        }


    }
}

.DialogTradeRightSide {
    width: 60%;
    height: 100%;
    background: #a68248;
    padding: 4px;

    .DialogTradeRightSideListItem {
        display: flex;
        width: 100%;
        margin-bottom: 4px;
        border-bottom: 1px dashed #eee;
        padding-bottom: 4px;

    }

    .DialogTradeRightSideListItemInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.DialogTradeTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    height: 35px;
}

.DialogTradeTitle {
    color: white;
    text-align: left;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.DialogTradeTitleSelectLvl {
    text-align: left;
    display: flex;
    gap: 11px; // можно добавить отступы между иконками
    flex-wrap: wrap;
    position: relative;
}

.DialogTradeTitleSelectLvlStar {
    width: 40px; // фиксированная ширина иконки
    height: 40px; // фиксированная высота иконки
    display: flex;
    justify-content: center;
    align-items: center;
    color: $main-color;
    display: flex;

    &.StarDisabled {
        color: #6b6b6d;

        .DialogTradeTitleSelectLvlStarText {
            color: #c2c2c2;
        }
    }
}

.DialogTradeCurrentLvl {
    color: $main-color;
    display: flex;
    align-items: center;
}

.DialogTradeTitleSelectLvlStarText {
    position: absolute;
    font-size: 12px;
}


.DialogTradeBuyCard {
    background: rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    text-align: left;
    margin-bottom: 10px;

    .DialogTradeBuyCardCell {
        margin-right: 18px;
    }

    .DialogTradeBuyCardCountExist {
        color: #797F8A;
        font-size: 12px;
    }

    .DialogTradeBuyCardPriceWrapper {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}


.DialogTradeBuyCardSelectList {
    display: flex;
    justify-content: start;
    gap: 10px;

    .DialogTradeBuyCardSelectItem {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 8px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 26px;

        .DialogTradeBuyCardSelectItemPrice {
            position: absolute;
            left: 0;
            bottom: 8px;
            margin: auto;
            display: flex;
            justify-content: center;
            width: 100%;
        }


        .DialogTradeItemTime {
            color: white;
            font-size: 11px;
            font-weight: bold;
        }

    }
}
            