
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .customRangeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;

    .numberInput {
        padding: 12px;
        width: 100%;
        border-radius: 8px;

        border: 1px solid #4f4b4d;

        font-size: 16px;
        outline: none;
        background: #00000033;
        color: white;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    
        // Убираем стрелки в Firefox
        -moz-appearance: textfield;

    }

    .rangeFieldContent {
        position: relative;
    }

    .rangeTitle {
        position: relative;
        text-align: left;
        margin-bottom: 10px;
        width: 100%;
        color: white;
        font-size: 13px;
    }

    .rangeInput {
        width: 95%;
        -webkit-appearance: none;
        appearance: none;
        height: 2px;
        background: #C49345;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        transition: background 0.3s ease;
        position: relative;
        top: -4px;
        

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #C49345;
            border: 4px solid #fff;
            // background: #007bff;
            cursor: pointer;
        }

        &::-moz-range-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #C49345;

            cursor: pointer;
        }
    }
}

.tgIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    pointer-events: none;
    top: 0;
    bottom: 0;
    margin: auto;
}
            