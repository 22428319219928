
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ImproveContainer {
    z-index: 110;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.ImproveItemItems {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ImproveItemName {
    height: 60px;
}

.ImproveItemItem {
    width: 48%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ImproveArrow {
    font-size: 22px;
    color: $main-color-dark;
    display: flex;
    align-items: center;
    height: 64px;
}

.WarnDescription {
    text-align: left;
    font-size: 14px;
    display: flex;
    justify-content: space-around;

    .WarnDescriptionIcon {
        font-size: 2.2em;
        color: #ddbb4c;
        display: flex;
        align-items: center;
    }

    .WarnDescriptionText {
        width: 80%;
    }
}


.ImproveItemElement {
    margin-right: 12px;
}

            