
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                // Login.scss

.form-container {
  text-align: center;
  height: 100%;
  background: linear-gradient(#0d0d14, #191413);
  padding-top: 114px;
  position: relative;
}

.form-content {
  text-align: center;
  width: 375px;
  height: 650px;
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease-in;
  transform-origin: 50% 0;
  padding: 16px;
 
}

.form-inner {
  border-radius: 8px;
  border: 1px solid #554939;
  background: linear-gradient(#282933, #322b2a);
  padding: 24px;
}

.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 0;
  color: white;
}

.password {
  margin-bottom: 20px;
}

.form-link {
  color: $main-color;
  text-decoration: none;
  text-align: left;
  font-size: 14px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #3f3a3b;
  color: $main-color;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  margin-bottom: 12px;
}

.submit-btn:hover {
  background-color: lighten(#3f3a3b, 5%);
}

            