
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ChatContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.ChatMessages {
    width: 100%;
    height: calc(100% - 65px);
    overflow-y: scroll;
    padding: 0 12px;
    padding-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 12px;


    .ChatMessageGroup {
        text-align: left;
        margin-bottom: 12px;
        font-size: 14px;

        .ChatMessageGroupItems {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .ChatMessageGroupName {
            color: #878787;
            margin-bottom: 10px;
        }

        .ChatMessageItem {
            background: #504b4c;
            color: white;
            padding: 10px 12px 10px 12px;
            width: 65%;
            border-radius: 2px;
            margin-bottom: 4px;
            position: relative;

            &:first-child:not(:only-child) {
                border-radius: 6px 6px 2px 2px;
            }

            &:last-child:not(:only-child) {
                border-radius: 2px 2px 6px 6px;
                margin-bottom: 0;
            }

            &:last-child .ChatMessageItemDate {
                position: absolute;
                right: 2px;
                bottom: -18px;
                font-size: 11px;
                color: #878787
            }
        }

        &.ChatMessageGroupMy {
            .ChatMessageGroupItems {
                align-items: end;
            }

            .ChatMessageGroupName {
                text-align: right;
            }
        }
    }
}


// No Message ICON and TEXT
.ChatNoMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;

    .ChatNoMessageText {
        color: white;
    }

    .ChatNoMessageIcon {
        width: 70px;
        height: 100px;
        background: get-image("components/interface/chat/shield.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}


// Send message INPUT and BTN
.ChatSendMessage {
    $margin-right: 12px;
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    align-items: center;
    padding: 0 12px;
    height: 65px;
    border-top: 1px solid #4b4b4b;
    justify-content: space-between;

    .ChatSendMessageField {
        width: calc(100% - 48px - #{$margin-right});
        margin-right: $margin-right;

        .ChatSendMessageFieldInput {
            border-radius: 6px;
            border: 1px solid $main-secondary-color;
        }
    }

    .ChatSendMessageBtn {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: $main-secondary-color;
        color: $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
}
            