
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .MobHealthCells {
    height: 7px;
    width: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    background: rgb(0, 0, 0);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -10px;
    z-index: 2;

    

    .Cell {
        flex-grow: 1;
        height: 100%;
        margin-right: 1px;
        transition: 0.05s all linear;
    }

    .CellLow {
        opacity: 0;
        animation: CellLowHpAnimate 1.75s infinite ease-in-out;
    }

    .Cell:last-child {
        margin-right: 0;
    }
}


@keyframes CellLowHpAnimate {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
            