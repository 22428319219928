
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                $bottom-side-height: 65px;

.BankComponent {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.BankTopSide {
    height: calc(100% - #{$bottom-side-height});
    overflow-y: scroll;
    @include modal-base-padding;

}

.BankBottomSide {
    height: $bottom-side-height;
    display: flex;
    align-items: center;

    @include modal-base-padding;

    border-top: 1px solid #4b4b4b;
    background-color: #302b2c;
    position: relative;
    top: -16px;


    .BankBtn {
        padding: 12px;
        background: $main-secondary-color;
        color: $main-color;
        width: 100%;
        margin: 0 auto;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-indent: 16px;
    }
}
            