
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .MobConainer {
    position: absolute;
    width: 64px;
    height: 64px;
    transform: scale(0.9);
}

.BossConainer {
    position: absolute;
    width: 160px;
    height: 160px;
}


.Mob {
    width: 64px;
    height: 64px;
    background: get-image("components/game-location/mobs/characters_breath.png") no-repeat;
    z-index: 1;
    position: relative;

    animation-timing-function: steps(6) !important;
    animation-iteration-count: infinite;
    pointer-events: none;

}

.MobDied {
    .Mob {
        animation-timing-function: steps(3) !important;
        animation-fill-mode: both !important;
    }

    .MobHealthBar {
        display: none;
    }
}



.Boss {
    width: 160px;
    height: 160px;
    background: get-image("components/game-location/mobs/bosses.png") no-repeat;
    z-index: 1;
    position: relative;

    animation-timing-function: steps(6) !important;
    animation-iteration-count: infinite;


    &.showImage {
        transform-origin: -52px -88px;
        transform: scale(0.65);
    }


}

.BossName {
    font-weight: bold;
    color: white;
    animation: boss-name-animate 1.6s ease-in-out infinite;
}

.BossAttackAnimate {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 64px;
    height: 64px;
    top: 0;
    bottom: 0;
}

.BossDied {
    .Boss {
        animation-timing-function: steps(3) !important;
        animation-fill-mode: both !important;
    }

}


.Drop {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 5;
    pointer-events: none;
}

@keyframes boss-name-animate {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0);
    }
}



.Time,
.Waves {
    position: absolute;
    top: 15px;
    margin: auto 0;
    color: white;
    font-weight: bold;
    left: 15px;
    text-shadow: 1px 1px 1px #2e2e2e;
}


.Waves {
    right: 15px;
    left: auto;
}
            