
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .loading-page {
    width: 100%;
    height: 100%;
    z-index: 999999999;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: linear-gradient(#0d0d14, #191413);
    transition: all 0.7s ease-in;
    opacity: 0;
    visibility: hidden;
    display: flex;

    &.show {
        visibility: visible;
        opacity: 1;
    }

    &.hide {
        opacity: 0;
        visibility: hidden;
    }
}

.loading-page-container {
    width: 100%;
    max-width: 375px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}

.loading-title {
    color: #fff;
    font-style: italic;
}

.loader-container {
    height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-inner-container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader-tip {
    width: 100%;
    display: flex;
    color: #fff;
    flex-direction: column;
    align-items: center;

    &__bar {
        width: 80%;
        height: 26px;
        border: 1px solid $main-color-dark;
        border-radius: 12px;
        margin-bottom: 12px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding: 2px;
        margin-top: 12px;

        &__inner {
            height: 100%;
            background: $main-color;
            border-radius: 12px;
        }
    }

    &__text {
        font-size: 14px;
        text-wrap: nowrap;
        margin-bottom: 12px;
    }
}

.loader-animation {
    width: 50px;
    aspect-ratio: 1.154;
    position: relative;
    background: conic-gradient(from 120deg at 50% 64%, #0000, $main-color 1deg 120deg, #0000 121deg);
    animation: l27-0 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
    zoom: 1.75;

    &:before,
    &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: inherit;
        transform-origin: 50% 66%;
        animation: l27-1 1.5s infinite;
    }

    &:after {
        --s: -1;
    }

    @keyframes l27-0 {

        0%,
        30% {
            transform: rotate(0)
        }

        70% {
            transform: rotate(120deg);
        }

        70.0000001%,
        100% {
            transform: rotate(360deg)
        }
    }

    @keyframes l27-1 {
        0% {
            transform: rotate(calc(var(--s, 1)*120deg)) translate(0)
        }

        30%,
        70% {
            transform: rotate(calc(var(--s, 1)*120deg)) translate(calc(var(--s, 1)*-5px), 10px);
        }

        100% {
            transform: rotate(calc(var(--s, 1)*120deg)) translate(0)
        }
    }
}

.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #fff;
  }
    .loader:before,
    .loader:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: 1s spin linear infinite;
    }
    .loader:after {
      color: #FF3D00;
      transform: rotateY(70deg);
      animation-delay: .4s;
    }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }

  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  @keyframes spin {
    0%,
    100% {
      box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: .2em -.2em 0 0 currentcolor;
    }
  }

            