
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .expAnimate {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-width: 64px;
    position: absolute;
    width: 100%;
}

.expAnimateValue {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 2px rgba(3, 3, 3, 0.6);
    opacity: 0;
    text-align: center;
    display: inline-block;
    animation: valueAnimate 1.95s ease-out;
    transition: all 1.75s ease-out;
    transform: translateY(48px);
    z-index: 50;
}

@keyframes valueAnimate {
    80% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
            