
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .Npc {
    width: 64px;
    height: 64px;
    background: get-image("components/game-location/npcs/npc.png") no-repeat;
    display: block;
    z-index: 1;
    position: relative;

    animation-timing-function: steps(6) !important;
    animation-iteration-count: infinite;
    transform: scale(0.9);

    cursor: pointer;



    &.Npc-raks {
        background: get-image("components/game-location/npcs/raks.png") no-repeat;
        width: 90px;
        height: 90px;
    }
}

.NpcConainer {
    position: absolute;
}

            