
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .TopToolbar {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 80px;
    display: flex;
    z-index: 20;
    border-top: 1px solid #83531e;
    border-bottom: none;
    background-color: #040403;
    box-shadow: -2px 0px 2px #000;
    display: flex;
    align-items: center;
    text-align: left;
    color: #845d1e;
    .ChatShortContainer {
        padding: 2px 4px;
    }
    .ChatShort {
        font-size: 12px;
    }
    .ChatIcon {
        width: 40px;
        height: 40px;
        background: get-image("components/interface/icons/chat.png");
        transform: scale(0.8);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .ChatCountMessage {
            width: 26px;
            height: 26px;
            background: #e00;
            border-radius: 50%;
            color: white;
            font-weight: bold;
            text-align: center;
            line-height: 22px;
            font-size: 11px;
            position: absolute;
            right: -9px;
            top: -9px;
            border: 2px solid #000;
            animation: messageAnimate 8.3s linear infinite;
        }
    }
}

.TopToolbarBack {
    display: flex;
    width: 15%;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #83531e;
    height: 100%;
    background-color: #2e2312;
    margin-top: -2px;
    margin-bottom: -2px;
    color: rgb(169, 174, 101);
    font-size: 24px;
}

            