
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .Location {
    height: calc(100% - 80px);
    width: 100%;
    position: relative;

    .LocationInner {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
        width: 100%;
    }
}

.LocationAnimate {
    transition: 0.4s transform linear;
    will-change: transform;
}

.LocationVillage {
    width: calc(calc(100% - 36px) * 3);
    height: calc(calc(100% - 30px) * 3);
    background-size: 100% 100%;
    background-image: get-image("components/game-location/maps/village/village.webp");
}

.ChangeLocation {
    width: 64px;
    height: 64px;
    border: 1px solid #000;
    position: absolute;
    z-index: 1;
    cursor: pointer;
}
            