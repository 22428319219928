
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .Toolbar {
    width: 100%;
    height: 80px;
    background: get-image("components/interface/game-panel/background-3.png");
    background-size: 100% 100%;
    background-position: -2px 0;
    position: absolute;
    bottom: 0;
    display: flex;
    z-index: 100;
    // background: #1D1110;

    // border: 1px solid #83531e;
    border-top: 1px solid #6c6563b3;
}

.Items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 136px;
}

.Backpack {
    background-image: get-image("components/interface/backpack/backpack-icon.png");
    background-size: 100% 66px;
    background-position-y: 40%;
    background-repeat: no-repeat;
    width: 64px;
    height: 100%;
    margin: 0 0px 0 8px;
    cursor: pointer;

    &.OpenedBackpack {
        background-image: get-image("components/interface/backpack/backpack-icon-close.png");
    }
}

.HpIcon,
.MpIcon {
    position: absolute;
    left: -2px;
    width: 13px;
    height: 13px;
    background-size: 100% 100%;
    z-index: 1;

    &.HpIcon {
        // background-image: get-image("components/interface/game-panel/healthIcon.png");
        top: 6px;
    }

    &.MpIcon {
        // background-image: get-image("components/interface/game-panel/manaIcon.png");
        top: 26px;
        left: -1px;

    }
}

.HpResults {
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
    z-index: 99;
    pointer-events: none;

    .toolbarHpAnim {
        position: absolute;

        &.toolbarHpAnimPlus {
            color: #729836;
            animation: floatAndFadeDown 0.4s linear reverse forwards;
        }

        &.toolbarHpAnimMinus {
            color: #A7140D;
            animation: floatAndFade 1.7s linear forwards;

        }
    }
}






.AdditionalInfo {
    width: 140px;
    height: 64px;
    display: flex;
    flex-flow: wrap;
    position: relative;
    margin: 0 12px 0 6px;
    top: 1px;

    .hp,
    .mp {
        border: 1px solid #6C656366;
        border-radius: 5px;
        height: 14px;
        color: white;
        font-size: 10px;
        line-height: 9px;
        text-align: center;
        font-weight: bold;
        position: absolute;
        width: 100%;
        overflow: hidden;
        background: #1E1F26;


        .valueText {
            z-index: 1;
            position: absolute;
            width: 100%;
            line-height: 13px;
        }

        .current {
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
        }

        &::before {
            position: absolute;
            left: -2px;
            top: -2px;
            content: " ";
            width: 12px;
            height: 12px;
            background-size: 100% 100%;
            z-index: 1;
        }
    }

    .hp {
        top: 7px;

        .current {
            // width: 20%;
            background: linear-gradient(14.53deg, #480608 17.76%, #A7140D 90.5%);
        }
    }

    .mp {
        top: 28px;

        .current {
            // width: 100%;
            background: linear-gradient(14.53deg, #0E174E 17.76%, #25338C 90.5%);
        }
    }




}

.exp {
    bottom: 0px;
    width: 100%;
    height: 4px;
    background: #2F3038;
    position: absolute;

    .current {
        background: linear-gradient(14.53deg, #3C5416 17.76%, #729836 90.5%);
        height: 100%;
        width: 65%;
        position: absolute;
        top: 0px;
    }

}

.expAndGold {
    position: relative;
    display: flex;
    top: 48px;
    width: 100%;
    justify-content: space-between;

    .currentExp {
        color: white;
        display: flex;
        font-weight: bold;
        flex-direction: column;
        font-size: 9px;
        position: relative;
        align-items: start;
    }

    .gold {
        display: flex;

        .coin {
            width: 8px;
            height: 8px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .count {
            color: #fff;
            font-size: 10px;
            font-weight: bold;
            text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.8);
            margin-right: 2px;
            line-height: 8px;
        }
    }

}


.quickPanelSelect {
    color: #e7c04b;
    font-size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    animation: quickPaneAnimate 1.4s linear infinite;
}

@keyframes quickPaneAnimate {
    0% {
        opacity: 0.6;
        transform: scale(1);
    }

    25% {
        opacity: 0.8;
        transform: scale(1.05);
    }

    50% {
        opacity: 1;
        transform: scale(1.1);
    }

    75% {
        opacity: 0.8;
        transform: scale(1.05);
    }

    100% {
        opacity: 0.6;
        transform: scale(1);
    }
}

@keyframes floatAndFadeDown {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    25% {
        transform: translate(1px, -3px);
    }

    50% {
        transform: translate(-1px, -8px);
    }

    75% {
        transform: translate(1px, -12px);
        opacity: 0.5;
    }

    100% {
        transform: translate(0, -15px);
        opacity: 0;
    }
}

@keyframes floatAndFade {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    25% {
        transform: translate(5px, -15px);
    }

    50% {
        transform: translate(-5px, -25px);
    }

    75% {
        transform: translate(5px, -35px);
        opacity: 0.5;
    }

    100% {
        transform: translate(0, -50px);
        opacity: 0;
    }
}
            