
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                * {
  backface-visibility: hidden;
  // pointer-events: auto;
}


body,
html,
#root {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#0d0d14, #191413);
}

* {
  box-sizing: border-box;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}




.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

// Генерация стилей для margin-left, margin-top, margin-right, margin-bottom
@for $i from 0 through 12 {
  $value: #{($i * 4)}px; // Вычисляемое значение от 0 до 48px (шаг 4px)

  .ml-#{$i} {
    margin-left: $value !important;
  }

  .mt-#{$i} {
    margin-top: $value !important;
  }

  .mr-#{$i} {
    margin-right: $value !important;
  }

  .mb-#{$i} {
    margin-bottom: $value !important;
  }

  .my-#{$i} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  .mx-#{$i} {
    margin-left: $value !important;
    margin-right: $value !important;
  }


  // Padding classes
  .pl-#{$i} {
    padding-left: $value !important;
  }

  .pt-#{$i} {
    padding-top: $value !important;
  }

  .pr-#{$i} {
    padding-right: $value !important;
  }

  .pb-#{$i} {
    padding-bottom: $value !important;
  }

  .py-#{$i} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }

  .px-#{$i} {
    padding-left: $value !important;
    padding-right: $value !important;
  }
}

.bold {
  font-weight: bold;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.green-text {
  color: rgb(33, 249, 9);
}

.red-text {
  color: #e00;
}

.cursive {
  font-style: italic;
}


.BtnWithPressed {
  transition: transform 0.05s ease-in;

  &:active {
    transform: scale(0.85) !important;
  }
}

.scale-bounce {
  animation: bounceAnimation 0.4s ease-in;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.horizontal-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* Темный фон */
  display: none;
  /* По умолчанию скрыт */
  flex-direction: row;
  /* Горизонтальное расположение элементов */
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.horizontal-loader.show {
  display: flex;
  /* Показать лоадер при активной загрузке */
}

.small {
  font-size: 13px;
}

.loader {
  width: calc(80px / cos(45deg));
  height: 8px;
  background: repeating-linear-gradient(-45deg, white 0 15px, #0000 0 20px) left/200% 100%;
  border-radius: 4px;
  animation: l1 1.75s infinite linear;
}

.close-modal {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  background: get-image("components/interface/backpack/close.png") no-repeat;
  background-size: 100% 100%;
  z-index: 2;
}


.close-popup {
  color: #55555f;
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;

  &:hover {
    color: lighten(#55555f, 10%);
  }
}

.bkg-modal {
  position: relative;
  background: linear-gradient(rgba(40, 41, 51, 0.85), rgba(50, 43, 42, 0.98)), get-image("components/interface/other/background-modal.jpeg") no-repeat !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.items {
  background: get-image("items/items.png");
}

.black-background {
  background-image: none !important;
}


.input-field {
  width: 100%;
  // margin-bottom: 20px;
  border-radius: 12px;
  padding: 14px;
  border: 1px solid #717070;
  box-sizing: border-box;
  background: none;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: white;
  }
}


@keyframes l1 {
  100% {
    background-position: right;
    opacity: 0.9;
  }
}

@keyframes bkgAnim {
  0% {
    background-size: 150%;
  }

  50% {
    background-size: 175%;
  }

  100% {
    transform: 150%;
  }
}

@keyframes enlarge {
  0% {
    transform: scale(1);
    opacity: 0.9;
  }

  100% {
    transform: scale(1.05);
    opacity: 1;
  }
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-15px);
  }

  75% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.popover {
  position: absolute;
  background-color: black;
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 200px;
  /* Ширина поповера */
  padding: 10px;
}

.popover-content h3 {
  margin-top: 0;
  font-size: 16px;
}

.popover-content p {
  margin: 0;
  font-size: 14px;
}

.popover-arrow {
  position: absolute;
  bottom: -8px;
  /* Смещение вниз */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;

  &.right {
    left: initial;
    right: 0;
    transform: translateX(0%);
  }
}


.warn-description {
  text-align: left;
  font-size: 14px;
  display: flex;
  justify-content: center;

  .warn-description-icon {
    font-size: 2.2em;
    width: 20%;
    color: #ddbb4c;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .warn-description-text {
    width: 100%;
  }
}
            