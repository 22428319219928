
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .DialogContainer {
    width: 100%;
    height: calc(100% - 120px);
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    animation: DialogModalAnimate 0.25s linear;
    z-index: 5;

    .DialogWrapper {
        display: flex;
        flex-flow: column;
        justify-content: end;
        height: 100%;
        padding-bottom: 20px;

    }

    
    .DialogContent {
        width: 90%;
        background: linear-gradient(#2b2e37, #332d2d);
        // border-radius: 2px;
        border: 1px solid #484649;
        text-align: left;
        padding: 14px;
        margin: 0 auto;
        margin-bottom: 9px;
        height: auto;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;


        &.DialogContentFullScreen {
            height: 100%;
            width: 100%;
            margin-bottom: 10px;
            padding: 0;
        }

        .DialogWho {
            font-size: 1.1em;
            color: #fefeff;
            font-weight: bold;
            margin-bottom: 14px;
        }

        .DialogMessage {
            font-size: 0.9em;
            color: #fefeff;
            white-space: pre-line;
        }
    }

    .DialogActions {
        @include action-button;
    }
}

.CloseDialog {
   @include close-modal;
}


@keyframes DialogModalAnimate {
    0% {
        background: rgba(0, 0, 0, 0.1);
    }

    100% {
        background: rgba(0, 0, 0, 0.75);
    }
}
            