
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .DungeonList {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    gap: 13px;
    justify-content: left;
}
.DungeonItem {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    .DungeonItemLvl {
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: $main-color;
    }

    .DungeonItemLock {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $main-color-dark;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.65);

        .DungeonItemLockAnimate {
            animation: animate-lock 0.35s linear;
        }
    }
}

@keyframes animate-lock {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(30deg);
    }
    50% {
        transform: rotate(0);
    }
    75% {
        transform: rotate(-30deg);
    }
    100% {
        transform: rotate(0);
    }
}

            