
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .AnimationAttack {
    width: 64px;
    height: 64px;
    background: get-image("components/interface/character/effects.png") no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: 0 1000px;

    animation-timing-function: steps(6) !important;
    animation-iteration-count: infinite;
}

.AnimationAttackValue {
    font-size: 20px;
    font-weight: bold;
    color: #f9e61a;
    text-shadow: 1px 2px rgba(3, 3, 3, 0.6);
    line-height: 64px;
    transform: translate(0, 0);
    opacity: 0;
    text-align: center;
    display: inline-block;
    animation: valueAnimate 0.95s ease-out;
    transition: all 0.75s ease-out;
    position: absolute;

    &.crit {
        font-size: 34px;
        color: #ae1b1b;
        animation: valueAnimateCrit 0.95s ease-out;
        font-weight: bold;
        text-shadow: 1px 2px rgba(3, 3, 3, 1);
    }
}

@keyframes valueAnimate {
    from {
        opacity: 1;
    }
    20% {
        font-size: 26px;
    }
    60% {
        opacity: 1;
    }
    to {
        font-size: 14px;
    }
}

@keyframes valueAnimateCrit {
    from {
        opacity: 1;
    }
    20% {
        font-size: 42px;
    }
    60% {
        font-size: 28px;
        opacity: 1;
    }
    to {
        font-size: 26px;
        opacity: 0.2;
    }
}

            