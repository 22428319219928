
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                
.ScarecrowTime {
    color: white;
    font-weight: bold;
    animation: ScarecrowTimeAnimate 2.2s infinite linear;
    text-shadow: 0 1px 0 #000;
    text-align: center;
  }
  
  @keyframes ScarecrowTimeAnimate{
    0% {
      transform: translateY(-4px);
    }
    50% {
      transform: translateY(4px);
    }
    100% {
      transform: translateY(-4px);
    }
  }
            