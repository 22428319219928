
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ChestLocation {
    .ChestContainer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 98;
        background-position: center;
        background-size: 100% 100%;
    }

    .Title {
        position: absolute;
        z-index: 1;
        left: 0;
        color: white;
        font-weight: bold;
        top: 40px;
        width: 100%;
        font-size: 32px;
        margin: 0;
        text-transform: capitalize;
    }

    .ChestItems {
        position: absolute;
        top: 100px;
        height: 300px;
        width: 100%;
        padding: 12px;

        .ChestItem {
            margin-bottom: 8px;
        }
    }

    .RefreshChest, .CloseChest {
        width: 45px;
        height: 45px;
        max-width: 60px;
        max-height: 60px;
        color: white;
        line-height: 45px;
        background: get-image("components/interface/backpack/btn-del.png");
        background-size: 100% 100%;
        font-size: 16px;
        position: absolute;
        bottom: 48px;
        left: 16px;
    }

    .RefreshChest {
        left: 65px;
    }
}

            