
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .InfoItemContainer {
    width: 100%;
    height: get-height-without-bar();
    position: absolute;
    z-index: 100;
    top: 0;
}

.InfoItemWrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: end;
    padding-bottom: 80px;
    width: 80%;
}

.InfoPrice {
    display: flex;
    justify-content: space-between;
    color: white;   
}

.InfoItem {
    height: fit-content;
    position: relative;
    top: 0;
    bottom: 120px;
    margin: auto;
    left: 0;
    right: 0;


   
    .InfoItemItem {
        display: flex;

        .InfoItemItemCell {
            margin-right: 14px;
        }

        // .InfoItemItemElementPosition {
        //     color: #ddbb4c;
        //     padding: 2px;
        //     font-size: 8px;
        //     position: absolute;
        //     right: 0;
        //     background-color: #000;
        //     font-weight: bold;
        //     border: 1px solid #83531e;
        //     bottom: -4px;
        //     border-radius: 4px;
        // }
    }
}

.ItemCount {
    font-size: 13px;
}

.itemInfoPopover {
    position: absolute;
    right: -5px;
    bottom: 25px;
}

.totalInfo {
    position: relative;
}

.itemPriceInfo {
    color: $main-color-light;
}
            