
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .GroupEndModal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;

    .GroupEndModalPanel {
        height: 360px;
        width: 90%;
        padding: 12px 24px;

        z-index: 5;
        left: 0;
        right: 0;
        top: 80px;
        bottom: 0;
        margin: auto;

        background: get-image("components/interface/backpack/interface-1.png") no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        border-radius: 4px;
        border: 1px solid #83531e;
        outline: 1px solid #000;
        box-shadow:
            inset 1px 1px 0 #000,
            inset -1px -1px 0 #000;

        &.GroupEndModalPanelLose {
            height: 70px;
        }
    }

    .titleLose {
        color: white;
        font-size: 32px;
        font-weight: bold;
        text-shadow:
            1px 1px 2px #000,
            -1px -1px 2px #000;
    }

    .Close {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 40px;
        height: 40px;
        background: get-image("components/interface/backpack/close.png") no-repeat;
        background-size: 100% 100%;
    }

    .GroupEndModalGroupTime {
        color: white;
        font-weight: bold;
        margin-bottom: 12px;
        font-size: 46px;
        position: relative;
        text-shadow:
            1px 1px 2px #000,
            -1px -1px 2px #000;

        
    }

}



.GroupImg {
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    .Lose,
    .Victory {
        width: 260px;
        height: 260px;

    }

    .Lose {
        background: get-image("components/interface/game-panel/lose.webp") no-repeat;
        background-size: 100% 100%;
    }

    .Victory {
        background: get-image("components/interface/game-panel/victory.webp") no-repeat;
        background-size: 100% 100%;
    }


    .GroupResult {
        height: 60px;
        width: 100%;
        background: rgba(0, 0, 0, 0.85);
        position: absolute;
        bottom: 0;
    }

}


.RateTable {
    border-collapse: collapse;

    th:last-child,
    td:last-child {
        text-align: right;
        font-weight: bold;
    }

    .RateDifficulty {
        color: $main-color;
    }
}

@keyframes timeSizeAnimate {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}
            