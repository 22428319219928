
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ActionsBar {
    position: relative;
    top: 6px;
    display: flex;
}

.ButtonAction,
.PaginationArrow {
    width: 45px;
    height: 45px;
    color: white;
    cursor: pointer;
    line-height: 45px;
    background: get-image("components/interface/backpack/btn-del.png");
    background-size: 100% 100%;
    font-size: 12px;
    opacity: 0.85;

    &:hover {
        opacity: 1;
    }
}

.Actions,
.Pagination {
    display: flex;
    width: 65px;
    justify-content: space-between;
}

.Actions {
    width: 145px;
}

.Pagination {
    width: 140px;
    position: absolute;
    right: 0;
    align-items: center;

    .PaginationInfo {
        width: 45px;
        height: 40px;
        background: #000;
        color: white;
        line-height: 40px;
        border-radius: 3px;
        border: 1px solid #444;
        font-size: 18px;
        font-weight: bold;
        margin-right: 4px;
        margin-left: 4px;
    }
}
.ItemsPagerItem {
    margin-bottom: 8px;
}
.ItemLevel {
    font-size: 22px;
    color: $main-color-dark;
    position: absolute;
    left: -8px;
    top: -8px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;


    span {
        position: absolute;
        color: white;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
    }
}

.LockCell {
    // color: #83531e;
    color: rgba(200,200,200, 1);
    height: 100%;
    font-size: 1.4em;
    line-height: 64px;
}

.ItemDisabled {
    position: relative;
    overflow: hidden;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.55);
        border-radius: 6px;
    }
}

.ItemsPagerSelected {
    width: 18px;
    height: 18px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 2px;
    background: #111;
    color: rgb(49, 59, 49);
    position: absolute;
    font-size: 14px;
    display: flex;align-items: center;
    justify-content: center;
    z-index: 10;
    right: 0;
    top: 0;
    font-weight: bold;

    &.Selected {
        color: rgb(53, 173, 53);
        
    }
}
            