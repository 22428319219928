
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .TgCoin {
    background-color: #1A1A1F;
    width: fit-content;
    border-radius: 20px;
    height: 20px;
    align-items: center;
    color: white;
    text-align: center;
    position: relative;
    display: flex;
    padding-right: 20px;

    .TgCoinCount {
        font-size: 10px;
        padding-left: 6px;
    }

    .TgCoinIcon {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
    }
}
            