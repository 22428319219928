
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .Effects {
    position: absolute;
    width: 200px;
    display: flex;

    transition: all 0.6s ease-in-out;

    .EffectElement {
        width: 32px;
        height: 32px;
        background-color: #999;
        border-radius: 6px;
        position: relative;

    }

    .EffectTime {
        position: absolute;
        bottom: -14px;
        white-space: nowrap;
        text-align: center;
        width: 100%;
    }
}

            