
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .HealEffect {
    pointer-events: none;
    z-index: 111;
}

.HealEffectStars {
    width: 100%;
    height: 60px;

    position: absolute;
    bottom: 40px;

    color: #b7cf31;

    font-weight: bold;
    animation: effectanimate 2.4s linear;

    span {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        text-align: left;
        height: 100%;
        opacity: 0;
        animation: effectofstars 2.3s linear;
        line-height: 60px;
    }
}

.HealEffectBottom {
    width: 100%;
    height: 12px;
    background: linear-gradient(0deg, rgba(183, 207, 49, 1) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 40px;
    opacity: 0;
    animation: effectofbottom 2.8s linear;
}

.healActive {
    color: #e4f586;
    text-shadow: 1px 0px 0px #b7cf31, -1px 0px 0px #b7cf31, 0px -1px 0px #b7cf31, 0px 1px 0px #b7cf31;
}

.healBarEffect {
    color: #b7cf31;
    text-shadow: 1px 0px 0px #e4f586, -1px 0px 0px #e4f586, 0px -1px 0px #e4f586, 0px 1px 0px #e4f586;
}

@keyframes effectofbottom {
    0% {
        opacity: 0.25;
    }
    20% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes effectanimate {
    to {
        transform: translateY(-130%);
    }
}

@keyframes effectofstars {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}

            