
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .CreateContainer {
    z-index: 110;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.CreateItemChild {
    min-height: 275px;
}

.CreateItemItems {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
}

.CreateItemName {
    height: 60px;
}

.CreateItemItem {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
}
.CreateModItems {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.CreateArrow {
    font-size: 22px;
    color: $main-color-dark;
    display: flex;
    align-items: center;
    height: 64px;
    width: 100%;
    justify-content: center;
}

.WarnDescription {
    text-align: left;
    font-size: 14px;
    display: flex;
    justify-content: space-around;

    .WarnDescriptionIcon {
        font-size: 2.2em;
        color: #ddbb4c;
        display: flex;
        align-items: center;
    }

    .WarnDescriptionText {
        width: 80%;
    }
}
            