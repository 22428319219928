
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .PropsParam {
    text-align: left;
    font-size: 14px;
    color: white;

    .ParamValue {
        font-size: 18px;
        color: $main-color;
        font-weight: bold;
    }
}


.PropsName {
    font-size: 16px;
    margin-bottom: 11px;
    color: white;
    font-weight: bold;
}
            