
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .loaderContainer {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  text-indent: 0;
}

.loader {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  border-top: 1px solid $main-color;

  border-right: 1px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
            