
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .Backpack {
    width: 100%;
    height: calc(100% - 80px);
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    border-bottom: 0;
}

.ItemsPager {
    position: absolute;
    top: 294px;
    width: 95%;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.Characteristic {
    position: absolute;
    left: 16px;
    color: white;
    top: 19px;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    width: 100px;

    .Level {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
    }

    .Params {
        margin-bottom: 14px;
        position: relative;
        span {
            float: right;
        }
    }

    .ParamsButton {
        width: 24px;
        height: 24px;
        background: get-image("components/interface/backpack/buttons-points.png") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        text-align: center;
        line-height: 24px;
        font-weight: bold;
        position: absolute;
        right: -34px;
        top: -6px;
    }
}

$armour-positions-one-x: -6px;

$armour-positions-two-x: 63px;

$armour-positions-three-x: 132px;

.Items {
    top: 10px;
    width: 200px;
    position: absolute;
    right: 6px;

    .Item {
        width: 64px;
        height: 64px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background: get-image("components/interface/backpack/armour-5.png");

        &.Necklace {
            left: $armour-positions-one-x;
            top: 25px;
            background-position-x: -384px;
        }
        &.Weapon {
            left: $armour-positions-one-x;
            top: 94px;
            background-position-x: 0px;
        }
        &.RingTwo {
            top: 163px;
            left: $armour-positions-one-x;
        }
        &.Ring {
            background-position-x: -64px;
        }

        &.Head {
            left: $armour-positions-two-x;
            background-position-x: -448px;
        }
        &.Armour {
            top: 69px;
            left: $armour-positions-two-x;
            background-position-x: -256px;
        }
        &.Belt {
            top: 138px;
            left: $armour-positions-two-x;
            background-position-x: -320px;
        }
        &.Legs {
            top: 207px;
            left: $armour-positions-two-x;
            background-position-x: -192px;
        }

        &.Cloak {
            left: $armour-positions-three-x;
            background-position-x: -576px;
        }
        &.Shield {
            top: 69px;
            left: $armour-positions-three-x;
            background-position-x: -512px;
        }
        &.Gloves {
            top: 138px;
            left: $armour-positions-three-x;
            background-position-x: -128px;
        }
        &.RingOne {
            top: 207px;
            left: $armour-positions-three-x;
        }

        &.Empty {
            background-position-x: 66px;
        }
    }
}


            