
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                
.ImproveItemContainer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    top: 0;
    left: 0;

    .ImproveItem {
        width: 90%;
        height: fit-content;
        background: linear-gradient(45deg, #212121, #5e4e42);
        padding: 12px;
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 60px;
        margin: auto;
        left: 0;
        right: 0;
        border-radius: 4px;
        border: 1px solid #7d7d75;
        outline: 1px solid #000;
        box-shadow:
            inset 1px 1px 0 #000,
            inset -1px -1px 0 #000;
    }

    .ImproveItemItems {
        height: fit-content;
        width: 100%;
    }

    .ImproveItemItem {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ImproveArrow {
        font-size: 22px;
        color: #ccc;
        width: 75%;
        float: right;
    }

    .WarnDescription {
        text-align: left;
        font-size: 14px;
        display: flex;
        justify-content: center;

        .WarnDescriptionIcon {
            font-size: 2.2em;
            width: 20%;
            color: #ddbb4c;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .WarnDescriptionText {
            width: 100%;
        }
    }
    .ImproveItemParam {
        width: 75%;
    }

    .ImproveItemElement {
        width: 20%;
    }

    .ImproveBtn {
        // width: 220px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: white;
        font-weight: bold;
        border-bottom: 4px solid #59703b;
        background: #8ca85a;
        box-shadow: 0 0 4px #204119;
        margin: 0 auto;
        border-radius: 4px;
    }
}

            