@function get-image($relative-path) {
  @return url("#{$base-image-path}#{$relative-path}?version=#{$version-image}");
}

@function get-height-without-bar() {
  @return calc(100% - 80px);
}

@function get-app-width() {
  @return 375px;
}

@function closeIcon() {
  @return 375px;
}

