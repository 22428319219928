
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ScarecrowDamageCover, .ScarecrowDamage {
    height: 100%;
    width: 100%;
}

.ScarecrowDamage {
    .ScarecrowDamageInfo {
        transform: translateX(-5px);
    }

    background-image: get-image("components/game-location/actions/scarecrow.png");

    &.ScarecrowSubType-0 {
        background-position: 0 0;
    }

    &.ScarecrowSubType-1 {
        background-position: -54px 0;
    }

    &.ScarecrowKilled {
        opacity: 0;
        animation: ScarecrowKilledAnimate 1.6s linear;
    }
}

@keyframes ScarecrowKilledAnimate {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
            