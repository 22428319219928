
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .Skill {
    position: absolute;
    bottom: 0px;
    top: 0;
    margin: auto 0;
    height: fit-content;

    transition: all 0.6s ease-in-out;
    z-index: 10;

    &.SkillHidden {
        transform: translateX(-64px);
    }

    .SkillItem {
        width: 64px;
        height: 64px;
        background: get-image("components/interface/icons/skills.webp");
        background-position: 0 0;
        transform: scale(0.8);
        border-radius: 50%;
        overflow: hidden;

        .SkillTime {
            position: absolute;
            width: 100%;
            background: rgba(0,0,0,.8);
            left: 0;
            bottom: 0;
            transition: height 0.3s ease-in;
        }


        &.fire {
            background-position: 0 0;
        }
        &.water {
            background-position: -64px 0;
        }
        &.defence {
            background-position: -192px 0;
        }


        
    }
}

            