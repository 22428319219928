
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ExchangeContainer {
    z-index: 110;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.ExchangeItemChild {
    min-height: 275px;
}

.ExchangeItemItems {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
}

.ExchangeItemName {
    height: 60px;
}

.ExchangeItemItem {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
}

.ExchangeModItems {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;


    .ExchangeModItemsItemRow {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
}

.SelectedResouce {
    border: 1px solid #729836 !important;
}


.ExchangeArrow {
    font-size: 22px;
    color: $main-color-dark;
    display: flex;
    align-items: center;
    height: 44px;
    width: 100%;
    justify-content: center;
}

.WarnDescription {
    text-align: left;
    font-size: 14px;
    display: flex;
    justify-content: space-around;

    .WarnDescriptionIcon {
        font-size: 2.2em;
        color: #ddbb4c;
        display: flex;
        align-items: center;
    }

    .WarnDescriptionText {
        width: 80%;
    }
}
            