// @include close-modal;
@mixin close-modal {
    color: #55555f;
    position: absolute;
    top: 7px;
    right: 10px;
    cursor: pointer;
    font-size: 19px;

    &:hover {
        color: lighten(#55555f, 10%);
    }
}


@mixin action-button-base-bkg {
    background:
        linear-gradient(160deg, #1e1f24 -3.41%, #1e1f24 96.6%),
        linear-gradient(222.29deg, #5B5657 2.38%, #6C6563 44.55%, #32251E 99.78%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

@mixin modal-base-padding {
    padding: 12px;
}

// @include action-button;
@mixin action-button {
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;


    button {
        display: block;
        margin-bottom: 9px;
        width: 100%;

        border: 1px solid transparent;

        @include action-button-base-bkg;
        color: #f8f9fe;
        padding: 8px;
        padding-left: 14px;
        padding-right: 14px;

        font-size: 14px;
        text-align: center;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            border: 1px solid transparent;
            background:
                linear-gradient(160deg, darken(#1e1f24, 5%) -3.41%, darken(#1e1f24, 5%) 96.6%),
                linear-gradient(222.29deg, #5B5657 2.38%, #6C6563 44.55%, #32251E 99.78%);
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
        }


        &:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }


        &.disabled {
            color: #6e6e6e;

            &:hover {
                @include action-button-base-bkg;
            }
        }
    }
}