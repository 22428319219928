
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .FullModalContainer {
    height: get-height-without-bar();
    background: linear-gradient(#0d0d14, #191413);
    position: fixed;
    padding: 20px;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;

    &.FullModalContainerDisabledPadding {
        padding: 20px 0;
    }
}

.FullModalHeader {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-bottom: 20px;

    h3 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.FullModalBack {
    font-size: 24px;
}

.FullModalExpandedMenu {
    position: absolute;
    right: 6px;
    width: 200px;
    background: linear-gradient(#0d0d14, #191413);
    bottom: 60px;
    color: white;
    // border-radius: 6px;
    border: 1px solid $main-color-dark;
    box-shadow: 2px 2px 14px #000;
    font-size: 14px;

    .FullModalExpandedItem {
        padding: 12px 4px;
        border-bottom: 1px solid $main-color-dark;

        &:hover {
            background: #000;
        }
        &:last-child {
            border-bottom: none;
        }
    }


    &::after {
        content: '';
        position: absolute;
        bottom: -14px;
        right: 0px;
        /* Смещение вниз */
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-top: 14px solid $main-color-dark;
      
    }
}

.FullModalContent {
    height: get-height-without-bar();
    background: linear-gradient(#282933, #322b2a);
    border-radius: 8px;
    padding: 20px;
    overflow-y: auto;

    &.FullModalContentDisabledPadding {
        padding: 0;
    }

    &.FullModalContentHideBottom {
        height: calc(100% - 25px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}


.FullModalButtons {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
    left: 0;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: space-between;
    align-items: center;
    color: white;

    .FullModalButton {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    .FullModalButtonActive {
        color: #C49345;
    }
}
            