
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .QuickInfoContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .QuickInfo {
        color: #cecece;
        font-size: 19px;
        font-weight: bold;
        height: 65px;
        background: rgba(0, 0, 0, 0.75);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        border: 1px solid #7d7d75;
        outline: 1px solid #000;
        box-shadow:
            inset 1px 1px 0 #000,
            inset -1px -1px 0 #000;

        border-left: none;
        border-right: none;

        animation: bounce-quick-info 1.45s ease-in;
        opacity: 0;
    }
}

@keyframes bounce-quick-info {
    0% {
        transform: translateY(0) scale(1.25);
        opacity: 0;
        height: 120px;
    }
    10% {
        transform: translateY(10px) scale(1.45);
        opacity: 0.8;
        height: 120px;
    }
    30% {
        transform: translateY(-20px);
        opacity: 1;
        height: 65px;
    }
    80% {
        transform: translateY(-40px);
        opacity: 0.6;
        height: 65px;
    }
    100% {
        transform: translateY(-160px);
        opacity: 0;
        height: 15px;
    }
}

            