
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .Map {
    .MapContainer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        border-bottom: none;
        z-index: 2;
        background: linear-gradient(12.3deg, #21232E 12.25%, #323746 58.75%, #0F1421 89.28%);
    }

    .MapContainerSelectDangeon {
        width: 90%;
        height: calc(100% - 60px);
        background-repeat: no-repeat !important;
        background-size: 100% !important;
        animation: animateIsland 2.2s linear infinite;
        margin: 0 auto;
        position: relative;
        top: 25px;
    }

    .MapStartPanelContainer {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        z-index: 100;
        top: 0;

        animation: AnimateBackground 0.15s linear;
    }



    .MapEnterBtn {
        height: 70px;
        width: 230px;
        background: get-image("components/interface/icons/start-button.webp") no-repeat;
        background-size: 100% 100%;
        box-shadow: none;
        border: none;
        bottom: 70px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 20px;
        margin: auto;
        left: 0px;
        right: 0;
        font-weight: bold;
    }


    .MapIcon {
        &::after {
            content: "";
            position: absolute;
            width: 85%;
            height: 85%;
            border-radius: 50%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: linear-gradient(229deg, rgba(0, 0, 0, 0) 0%, rgba(84, 44, 27, 0) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(144, 62, 52, 0) 60%, rgba(252, 70, 107, 0) 100%);
            background-size: 350% 350%;
        }
    }

    .MapRatingBtn {
        width: 56px;
        height: 56px;
        background: get-image("components/interface/icons/cup.webp") no-repeat;
        position: absolute;
        bottom: 120px;
        background-size: 100% 100%;
        left: 10px;

        &::after {

            animation: gradientAnimation 2.5s infinite;
        }

    }

    .MapBookBtn {
        width: 56px;
        height: 56px;
        background: get-image("components/interface/icons/book.webp") no-repeat;
        position: absolute;
        bottom: 120px;
        background-size: 100% 100%;
        right: 10px;

        &::after {

            animation: gradientAnimation 2.2s infinite;
        }

    }

    .MapStartPanel {
        height: fit-content;
        width: 80%;
        padding: 6px 12px;

        z-index: 5;
        left: 0;
        right: 0;
        top: 80px;
        bottom: 0;
        margin: auto;

        background: get-image("components/interface/backpack/interface-1.png") no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        border-radius: 4px;
        border: 1px solid #83531e;
        outline: 1px solid #000;
        box-shadow:
            inset 1px 1px 0 #000,
            inset -1px -1px 0 #000;


        .MapStartBtn {
            display: block;
            width: 200px;
            padding: 6px 12px;
            height: 45px;
            z-index: 1;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 20px;
            color: white;
            font-weight: bold;
            border-bottom: 2px solid #59703b;
            background: #8ca85a;
            box-shadow: 0 0 4px #204119;
            margin-bottom: 16px;
            margin-top: 16px;
        }

        .Close {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 40px;
            height: 40px;
            background: get-image("components/interface/backpack/close.png") no-repeat;
            background-size: 100% 100%;
        }

        .MapTitleNotice {
            font-size: 14px;
            color: white;

            display: flex;
            justify-content: space-around;
            align-items: center;

            .WarnDescriptionIcon {
                font-size: 2.2em;
                color: #ddbb4c;
                display: flex;
                align-items: center;

            }

            .WarnDescriptionText {
                width: 80%;
                text-align: left;
            }
        }

        .MapStartGroup {
            width: 100%;
            position: relative;
            top: 8px;

            .MapStartGroupLevel {
                margin: 0 auto;
            }


        }

        .MapStartPanelTitle {
            color: white;
            margin: 0;
        }

        .MapStartPanelAddUser {
            color: #e7c04b;
            font-size: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            animation: animatePlus 0.8s linear infinite;
            opacity: 0.6;

            &.AddUserSet {
                width: 88%;
                height: 88%;
                background: get-image("components/interface/face/warrior.png") no-repeat;
                background-size: 100% 100%;
                animation: none;
                opacity: 1;
                border-radius: 6px;
                position: absolute;
                right: 0;
                left: 0;
                margin: auto;
                top: 0;
                bottom: 0;
                color: white;
                font-size: 14px;
                font-weight: bold;
            }

            .MapStartUserClose,
            .MapStartUserName,
            .MapStartUserRate {
                position: absolute;
            }

            .MapStartUserClose {
                top: -11px;
                right: -11px;

                width: 22px;
                height: 22px;
            }

            .MapStartUserName {
                position: absolute;
                top: -24px;
            }

            .MapStartUserRate {
                position: absolute;
                bottom: -24px;
            }
        }
    }


    .MapInfoBar {
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 20px;

        .MapInfoBack {
            font-size: 20px;
            width: 5%;
        }

        .MapTitle {
            z-index: 1;
            color: white;
            font-weight: bold;
            font-size: 22px;

            width: 95%;
            position: relative;
            left: -2.5%;
        }

        .MapTitleIcons {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            font-size: 18px;
            display: flex;
            align-items: center;
            color: $main-color-light;
        }

        h3 {
            margin: 0;
        }
    }

    .MapNavigationGroup {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: 40%;
        padding: 0 14px;

        .MapNavigationItem {
            color: #474D5C;
            font-size: 24px;
        }
    }



    .MapNavigationCloseMap {
        width: 45px;
        height: 45px;
        max-width: 60px;
        max-height: 60px;
        color: white;
        line-height: 45px;
        background: get-image("components/interface/backpack/btn-del.png");
        background-size: 100% 100%;
        font-size: 16px;
    }

    .MapNavigationActionBar {
        position: absolute;
        bottom: 47px;
        left: 7px;
        display: flex;

        >div {
            margin-right: 6px;
        }
    }
}

.RateTable {

    tr td:last-child,
    tr th:last-child {
        text-align: right;
    }

    tbody td {
        font-size: 13px;
    }
}

.MapStartGroupLevelStarsTitle {
    color: white;
    margin: 0;
    margin-top: 22px;
}

.MapStartGroupLevelStars {
    display: flex;
    margin: 14px auto;
    justify-content: space-around;
    font-size: 32px;
    color: #666;

    &.MapStartGroupLevelStarsTable {
        font-size: 36px;
        margin: 0;
        justify-content: space-between;
    }

    .MapStartGroupLevelSet {
        color: $main-color;
    }
}

.MapRateCup {
    color: #666;
    font-size: 40px;

    &.Selected {
        color: $main-color;

    }
}

.MapStartGroupItem {
    overflow: visible !important;
}

@keyframes animateIsland {
    0% {
        transform: scale(1) translateY(0);
    }

    50% {
        transform: scale(1.02) translateY(-5px);
    }

    100% {
        transform: scale(1) translateY(0);
    }
}

@keyframes animatePlus {
    0% {
        transform: scale(1);
        opacity: 0.6;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.9;
    }

    100% {
        transform: scale(1);
        opacity: 0.6;
    }
}

.RateCotainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: get-image("components/interface/backpack/interface-1.png") no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 10;
    color: white;
    border: 1px solid #83531e;
}

.MapBookList {
    display: flex;
    gap: 17px;
    justify-content: start;
}



@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
            