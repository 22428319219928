
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .game-container {
  background: linear-gradient(#0d0d14, #191413);

  // background: var(--images-path);

  touch-action: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  height: 100%;
}

.game {
  text-align: center;
  width: 375px;
  height: 650px;
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease-in;
  transform-origin: 50% 0;
}


.damage-pane {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  // pointer-events: none;

  .section {
    fill: rgba(20, 7, 4, 0.75);
    stroke: #83531e;
    stroke-width: 1;
  }

  .section-invisible {
    fill: transparent;
    cursor: pointer;
  }

  .section-text {
    font-size: 16px;
  }

  .section-active path.section {
    fill: rgba(20, 7, 4, 1);
    transition: fill 0.1s ease-in-out;
  }

  .attack-pane-icon {
    width: 50px;
    height: 50px;
    // background: get-image("icons/attack-bar.png") no-repeat;
    opacity: 0.6;

    &.icon-closed {
      background-position: 0 0;
    }

    &.icon-shield {
      background-position: -50px 0;
    }

    &.icon-magic {
      background-position: -100px 0;
    }

    &.icon-counter-attack {
      background-position: -150px 0;
    }

    &.icon-action {
      background-position: -200px 0;
    }

    &.icon-attack {
      background-position: -250px 0;
    }
  }

  .section-active .attack-pane-icon {
    opacity: 1;
  }
}

.Canvas-of-game {
  width: 100%;
  height: 100%;
}

.pointer-none {
  pointer-events: none;
}

.Close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  background: get-image("components/interface/backpack/close.png") no-repeat;
  background-size: 100% 100%;
  filter: brightness(1.35);
}

.ItemsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .Item {
    width: 64px;
    height: 64px;
    border: 1px solid transparent;
    background:
      linear-gradient(133deg, rgba(0, 0, 0, 0) 0%, rgba(114, 60, 37, 0.15) 50%, rgba(0, 0, 0, 0) 100%),
      /* круглый градиент */
      linear-gradient(to right, #1E1F26, #1E1F26),
      /* фон элемента */
      linear-gradient(222.29deg, rgba(91, 86, 87, 0.7) 2.38%, rgba(108, 101, 99, 0.7) 44.55%, rgba(50, 37, 30, 0.7) 99.78%);
    /* градиент границы */
    background-clip: padding-box, padding-box, border-box;
    background-origin: padding-box, padding-box, border-box;
    border-radius: 6px;


    cursor: pointer;
    position: relative;
    // overflow: hidden;
    transition: all 0.2s ease-in-out;

    &:not(.DisableHover):hover {
      border: 1px solid $main-color;
    }



    &.ItemSelected {
      border: 1px solid #53931f;
    }

    .ItemCount {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 9px;
      color: white;
      height: 15px;
      padding: 0 4px;
      line-height: 15px;
      font-weight: bold;
      text-align: center;
      background: rgba(0, 0, 0, 0.6);
      border-top-left-radius: 3px;
      border-bottom-right-radius: 6px;
    }
  }
}
            