
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .ActionContainer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    z-index: 20;
}

.ActionWrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
    position: relative;
    top: 40px;

    &.ActionWrapperTop {
        top: 0;
    }
}



.ActionContent {
    width: 90%;
    background: linear-gradient(#2b2e37, #332d2d);
    border: 1px solid #484649;
    text-align: left;
    padding: 14px;
    margin: 0 auto;
    margin-bottom: 9px;
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;

    &.ActionContentRound {
        border-radius: 10px;
    }

}

.ActionContentInfo {
    margin: 0 -15px;
    border-top: 1px solid rgba(108, 101, 99, 0.7);
    box-shadow: 0 -1px 0 #000;
    padding-top: 14px;
    margin-top: 14px;
    padding-left: 14px;
    padding-right: 14px;
}

.ActionButtons {
    @include action-button;
}

.CloseDialog {
    @include close-modal;

}
            