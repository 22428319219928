
                $base-image-path: "https://s3.enchantedworld.io/images/";
                $version-app: "0.3.0";
                $version-image: "1.0.5";

                @import "src/scss/_function";
@import "src/scss/_variables";
@import "src/scss/_mixins";
                
                .LocationIcons {
    width: 64px;

    .LocationIconsMain {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 128px;
        right: 6px;
        transform: translateX(70px);
        transition: all 0.3s ease-in;

        &.MainAvailable {
            transform: translateX(0);
        }

        .Icon {
            position: relative;
            width: 52px;
            height: 52px;
            cursor: pointer;
            background-repeat: no-repeat !important;
            background-size: 52px 52px !important;


            &::after {
                content: "";
                position: absolute;
                width: 85%;
                height: 85%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background:  linear-gradient(229deg, rgba(0,0,0,0) 0%, rgba(84,44,27,0) 40%, rgba(255,255,255,0.4) 50%, rgba(144,62,52,0) 60%, rgba(252,70,107,0) 100%);
                background-size: 350% 350%;
            }
        }
    }

    .LocationGroupIcons {
        position: absolute;
        right: 6px;
    }

    .MapIcon {
        background: get-image("components/interface/icons/map.webp");
        &::after {
            animation: gradientAnimation 2.5s infinite;
        }
    }

    .RatingIcon {
        background: get-image("components/interface/icons/rating.webp");
        margin-top: 6px;

        &::after {
            animation: gradientAnimation 2.7s infinite;
        }
    }

    .ArenaIcon {
        background: get-image("components/interface/icons/arena.webp");
        margin-top: 6px;

        &::after {
            animation: gradientAnimation 2.6s infinite;
        }
    }

    .SettingIcon {
        background: get-image("components/interface/icons/setting.webp");
        margin-top: 6px;

        &::after {
            animation: gradientAnimation 2.8s infinite;
        }
    }
}

.ExitIcon,
.ChestIcon {
    width: 52px;
    height: 44px;
    background: get-image("components/interface/icons/exit.webp");
    position: absolute;
    background-size: 100% 100%;

    bottom: 64px;
    right: 0;
    cursor: pointer;
}

.ChestIcon {
    bottom: 128px;
    background: get-image("components/interface/icons/chest.webp");
    background-size: 100% 100%;
    width: 52px;
    height: 52px;
}


@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
            